import Head from 'next/head';
import { Container } from 'react-bootstrap';

import ThemeProvider from 'src/components/layout/themeProvider';
import Login from 'src/components/login';
import styles from 'src/components/login/login.module.scss';

export default function LoginPage({ redirectPath }) {
    return (
        <ThemeProvider>
            <div className={styles.contentWrapper}>
                <Head>
                    <title>Login - Wit Brands Console</title>
                </Head>

                <Container className={styles.content}>
                    <Login redirectPath={redirectPath} />
                </Container>
            </div>
        </ThemeProvider>
    );
}

export const getServerSideProps = ({ query }) => {
    return {
        props: {
            redirectPath: query?.next || null,
        },
    };
};
