import { useRouter } from 'next/router';
import { useState } from 'react';
import {
    Button,
    Form, OverlayTrigger,
    Popover, Spinner
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

import styles from 'src/components/login/login.module.scss';
import Api from 'src/data/api';
import * as actions from 'src/redux/actions';

function TotpVerification({ redirectPath, onBack, dispatch }) {
    const router = useRouter();

    const [code, setCode] = useState('');
    const [submitEnabled, allowSubmit] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        allowSubmit(false);
        toast.dismiss();

        if (!code) {
            toast.error('Please enter your code to proceed.');
            allowSubmit(true);
            return;
        }

        try {
            await Api.request({
                method: 'POST',
                url: `/auth/totp/verify`,
                data: {
                    code: code.trim(),
                },
            });

            await dispatch(actions.loadUser());
            await router.push(redirectPath || '/');
        } catch (err) {
            // Return to login if user is not logged in
            if (err.response.status === 403 && err.response.data.auth_state) {
                return onBack();
            }

            allowSubmit(true);
        }
    };

    return (
        <>
            <img
                src="/assets/wit-logo.svg"
                alt="Wit"
                className={styles.brandLogo}
            />
            <div
                className={styles.contentHeader}
                style={{ marginBottom: '3rem' }}
            >
                <h1>Two-Factor Login</h1>
                <p>
                    You've enabled two-factor authentication!
                    <br />
                    Use your authenticator app to retrieve your code.
                </p>
            </div>

            <Form onSubmit={handleSubmit} className={styles.totpForm}>
                <Form.Group controlId="input-totp">
                    <Form.Label>Your verification code:</Form.Label>
                    <Form.Control
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                        autoFocus
                    />
                </Form.Group>

                <Button
                    variant="light"
                    type="submit"
                    disabled={!submitEnabled}
                    className="mt-3"
                >
                    {submitEnabled ? (
                        'Submit Code'
                    ) : (
                        <Spinner animation="border" size="sm" />
                    )}
                </Button>

                <OverlayTrigger
                    trigger="click"
                    placement="top"
                    rootClose
                    overlay={
                        <Popover>
                            <Popover.Title>
                                Can't access your codes?
                            </Popover.Title>
                            <Popover.Content>
                                <p>
                                    If you no longer have access to your
                                    authenticator codes, you can use one of your
                                    backup codes. Remember, each backup code can
                                    only be used once.
                                </p>
                                <p className="mb-0">
                                    If you can't use a backup code, please
                                    contact us.
                                </p>
                            </Popover.Content>
                        </Popover>
                    }
                >
                    <Button variant="link" type="button" className="mt-3">
                        Can't access your codes?
                    </Button>
                </OverlayTrigger>
            </Form>
        </>
    );
}

export default connect()(TotpVerification);
