import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import styles from 'src/components/login/login.module.scss';
import Api from 'src/data/api';

let validationSchema = Yup.object().shape({
    email: Yup.string().email().label('Email'),
});

export default function RequestPasswordReset({ onComplete }) {
    const [email, setEmail] = useState('');
    const [submitEnabled, allowSubmit] = useState(true);

    const handleSubmit = async (e) => {
        e.preventDefault();
        allowSubmit(false);

        try {
            await validationSchema.validate({
                email,
            });
        } catch (err) {
            allowSubmit(true);
            toast.error(err.errors.join(', '));
            return;
        }

        try {
            await Api.request({
                method: 'POST',
                url: `/auth/request_reset`,
                data: {
                    email,
                },
            });

            toast('Check your email for your password reset link.', {
                duration: 8000,
            });
            onComplete();
        } catch (err) {
            console.error(err);
            allowSubmit(true);
        }
    };

    return (
        <>
            <img
                src="/assets/wit-logo.svg"
                alt="Wit"
                className={styles.brandLogo}
            />
            <div
                className={styles.contentHeader}
                style={{ marginBottom: '3rem' }}
            >
                <p>
                    If you have a brand account, we'll send you
                    <br /> an email to reset your password.
                </p>
            </div>

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="input-email">
                    <Form.Label>Enter your email address</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button
                    variant="light"
                    type="submit"
                    disabled={!submitEnabled}
                    className="mt-3"
                >
                    {submitEnabled ? (
                        'Request Password Reset'
                    ) : (
                        <Spinner animation="border" size="sm" />
                    )}
                </Button>
                <Button
                    variant="link"
                    type="button"
                    onClick={() => onComplete()}
                    className="mt-3"
                >
                    Back
                </Button>
            </Form>
        </>
    );
}
