import { TinyColor } from '@ctrl/tinycolor';
import Head from 'next/head';
import { connect } from 'react-redux';

function ThemeProvider({ children, user }) {
    const themePrimary = new TinyColor(
        (user.loggedIn && user.data['primary_color_hex']) || '#4a37ca'
    );
    const themePrimaryHsl = themePrimary.toHsl();

    return (
        <>
            <Head>
                <style key="bs-theme">{`
                    :root {
                        --bs-primary: ${themePrimary.toHslString()};
                        --bs-primary-h: ${Math.round(themePrimaryHsl.h)};
                        --bs-primary-s: ${Math.round(themePrimaryHsl.s * 100)}%;
                        --bs-primary-l: ${Math.round(themePrimaryHsl.l * 100)}%;
                    }
                `}</style>
            </Head>
            {children}
        </>
    );
}

export default connect((store) => ({ user: store.user }))(ThemeProvider);
