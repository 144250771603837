import _ from 'lodash';

import Api from 'src/data/api';
import * as types from './actionTypes';

export function loadUser() {
    return async (dispatch) => {
        dispatch({
            type: types.loadUserStart,
        });

        try {
            const resp = await Api.request({ url: '/user/me' });
            let data = resp.data;
            if (!_.isObject(data)) {
                data = null;
            }

            return dispatch({
                type: types.loadUserSuccess,
                data,
            });
        } catch (err) {
            if (err.response.status === 401) {
                return dispatch({
                    type: types.loadUserAuthRequired,
                    data: err.response.data,
                });
            }

            return dispatch({
                type: types.loadUserFail,
            });
        }
    };
}

export function logout() {
    return async (dispatch) => {
        dispatch({
            type: types.authLogoutStart,
        });

        try {
            await Api.request({ url: '/auth/logout' });
            Api.destruct();
            dispatch({
                type: types.authLogoutSuccess,
            });
        } catch (err) {
            dispatch({
                type: types.authLogoutFail,
            });
        }
    };
}
