import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';

import styles from 'src/components/login/login.module.scss';
import RequestPasswordReset from 'src/components/login/requestPasswordReset';
import TotpVerification from 'src/components/login/totp';
import Api from 'src/data/api';

function Login({ redirectPath, dispatch }) {
    const router = useRouter();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [submitEnabled, allowSubmit] = useState(true);
    const [resetPasswordVisible, showResetPassword] = useState(false);
    const [totpVisible, setTotpVisible] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        allowSubmit(false);
        toast.dismiss();

        if (!email || !password) {
            toast.error('Please enter your email and password to proceed.');
            allowSubmit(true);
            return;
        }

        try {
            const { data } = await Api.request({
                method: 'POST',
                url: `/auth/login`,
                data: { email, password },
            });

            if (data?.user.hasTwoFactor) {
                allowSubmit(true);
                setTotpVisible(true);
            } else {
                return router.push(redirectPath || '/');
            }
        } catch (err) {
            allowSubmit(true);
        }
    };

    useEffect(() => {
        if (!!router.query.totp) {
            setTotpVisible(true);
        }
    }, [router.query]);

    if (totpVisible) {
        return (
            <TotpVerification
                redirectPath={redirectPath}
                onBack={() => setTotpVisible(false)}
            />
        );
    }
    if (resetPasswordVisible) {
        return (
            <RequestPasswordReset onComplete={() => showResetPassword(false)} />
        );
    }

    return (
        <>
            <img
                src="/assets/wit-logo.svg"
                alt="Wit"
                className={styles.brandLogo}
            />
            <div
                className={styles.contentHeader}
                style={{ marginBottom: '3rem' }}
            >
                <h1>Let's get started</h1>
            </div>

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="input-email">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="input-password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button
                    variant="light"
                    type="submit"
                    disabled={!submitEnabled}
                    className="mt-3"
                >
                    {submitEnabled ? (
                        'Login'
                    ) : (
                        <Spinner animation="border" size="sm" />
                    )}
                </Button>
                <Button
                    variant="link"
                    type="button"
                    onClick={() => showResetPassword(true)}
                    className="mt-3"
                >
                    Reset password
                </Button>
            </Form>
        </>
    );
}

export default connect()(Login);
